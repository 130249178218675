<template lang="pug">
div
  .thank-you-box
    b-row
      b-col.text-center
        img(:src="wuwowThankyouPng" alt="WUWOW 線上英語教學 感謝您")
    b-row.mt-5
      b-col
        span.thank-you-content
          | 謝謝您提供的訊息，我們已經收到您的資料！
          br
          | 我們會盡快安排專員與您聯絡！
        p.mt-2.mb-0.thank-you-content （懶人包限擇一下載）
    b-row.mt-5
      b-col.thank-button-group
        b-button.go-back-home-btn.m-bottom-5(@click="chooseGift('arthur')", variant="primary" href="https://wuwow-store.s3.ap-southeast-1.amazonaws.com/pictures/video/2023_jr_king_arthur_legend_the_sword_in_the_stone_voice_book" target="_blank", :class="{disabled:hasGift!=='arthur' && hasGift!=='notChoose'}") 下載 兒童英文繪本有聲書
        b-button.go-back-home-btn.m-bottom-5(@click="chooseGift('cambridge')", variant="primary" href="https://wuwow-store.s3.ap-southeast-1.amazonaws.com/pictures/Fast+guide/202305_jr_cambridge_english_starters_words_book.pdf" target="download",:class="{disabled:hasGift!=='cambridge' && hasGift!=='notChoose'}") 下載 劍橋兒童英檢單字書
        b-button.go-back-home-btn(@click="backToHome()" variant="outline-primary") 返回WUWOW Jr官網
</template>

<script>
import wuwowThankyouPng from '@/assets/wuwow_ty.png';
export default {
  name: 'ThankYouPage',

  data() {
    return {
      wuwowThankyouPng: wuwowThankyouPng,
      head: null,
      setFreecoinsCvq: null,
      freecoins: null,
      hasWritedPhone: localStorage.getItem('hasWritedPhone') || '',
      hasGift: 'notChoose',
    };
  },

  created() {
    // this.initLinePoint();
  },
  destroyed() {
    // this.head.removeChild(this.setFreecoinsCvq);
    // this.head.removeChild(this.freecoins);
  },

  methods: {
    chooseGift(giftString) {
      this.hasGift = giftString;
    },

    backToHome() {
      this.$router.push({ path: '/' });
    },
    initLinePoint() {
      // Line Point 前置作業
      this.head = document.querySelector('head');
      this.setFreecoinsCvq = document.createElement('script');
      this.setFreecoinsCvq.setAttribute('type', 'text/javascript');
      this.setFreecoinsCvq.innerHTML = `
        if(localStorage.getItem('setFreecoinsCvq') == "true") {
          let phone = localStorage.getItem('trialPhone');
          var freecoins_cvq = [
            {
              app: "FREECOINS_33485",
              domain: ".wuwowjr.tw",
              cv: [
                {
                  action: "REGISTRATION",
                  order: phone,
                  item: "trial2023Form",
                  t_price: "1",
                  quantity: "1",
                  memo: "JR官網試讀感謝頁"
                }
              ]
            }
          ];
        }`;
      this.freecoins = document.createElement('script');
      this.freecoins.setAttribute('src', 'https://freecoins.line-apps.com/lfc5.js');
      this.freecoins.setAttribute('async', '');
      this.head.appendChild(this.setFreecoinsCvq);
      this.head.appendChild(this.freecoins);
    },
  },
};
</script>
<style scoped>
.thank-you-box {
  height: 100vh;
  text-align: center;
  background-color: #F5F5F5;
  background-image: url(~@/assets/thank_you_bg.png);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.thank-you-content {
  font-size: 18px;
  color: var(--text-light-color);
}

.thank-button-group {
  max-width: 322px;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
}

.m-bottom-5 {
  margin-bottom: 20px;
}

.go-back-home-btn {
  font-size: 16px;
  padding: 15px 0px;
}

.go-back-home-btn.disabled{
opacity: 0.25;
}
</style>
